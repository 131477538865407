import * as BABYLON from "babylonjs";
import * as REAL from "real_api_bbl";

export async function StartScene(bblScene) {
  // await addSun(bblScene);
  await addAreaLight(bblScene);
  addCube(bblScene);
  await addCamera(bblScene);
  bblScene.transform.deselectPicked();
}

export async function addAreaLight(that) {
  const light = new REAL.AreaLight(that.scene);
  light.position.set(0, 2, 1);
  light.intensity = 10;
  light.rotation.x = -Math.PI / 4;
  that.addAreaLight(light);
}

export async function addCamera(that) {
  const scene = that.scene;
  const camera = new REAL.EYE(scene);
  const giz = await that.addCamera(camera, true);
  giz.position.set(4.47321, 3.62687, 4.2057);
  let x = radians(18.5593);
  let y = radians(-133.308);
  let z = radians(0);
  giz.rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(x, y, z);
}

function radians(degree) {
  return BABYLON.Tools.ToRadians(degree);
}

export async function addSun(that) {
  const scene = that.scene;
  const light = new REAL.SunLight(scene);
  const lightGiz = await that.addSunLight(light);
  lightGiz.position.set(4.07625, 5.90386, 1.00545);
}

export function addCube(that) {
  const scene = that.scene;
  const cube = BABYLON.MeshBuilder.CreateBox("Cube", { size: 1 }, scene);
  that.addToGiz(cube);
  // cube.position.x += 3;
}
