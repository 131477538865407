import * as BABYLON from "babylonjs";
import * as REAL from "real_api_bbl";
import { getCurrentInstance } from "vue";
import EventManager from "@/utils/EventManager";
import Grid from "@/render_utils/three_tools/Grid";
import Transform from "@/render_utils/three_tools/Transform";
import SvgMesh from "@/render_utils/three_tools/mesh_tools/SvgMesh";
import { StartScene } from "@/render_utils/three_tools/scenes/start_scene";
import GizManager from "@/render_utils/three_tools/gizmos_tools/GizManager";
import { NameExtra } from "@/render_utils/three_tools/gizmos_tools/giz_tools";
import { createSunGiz } from "@/render_utils/three_tools/gizmos_tools/sun_gizmos";
import { createAreaGiz } from "@/render_utils/three_tools/gizmos_tools/area_gizmos";
import { createSpotGiz } from "@/render_utils/three_tools/gizmos_tools/spot_gizmos";
import { createPointGiz } from "@/render_utils/three_tools/gizmos_tools/point_gizmos";
import { createCameraGiz } from "@/render_utils/three_tools/gizmos_tools/camera_gizmos";
import { createBoundingBox } from "@/render_utils/three_tools/gizmos_tools/model_gizmos";

export default class Scene {
  constructor(cache) {
    this.canvas = this.getCanvas();
    this.instance = getCurrentInstance();
    const { proxy } = this.instance;
    this.engine = new BABYLON.Engine(this.canvas, true);
    this.background = new BABYLON.Color4.FromHexString("#202020");
    this.modelData = {
      ignoreList: [],
      modelLights: [],
      modelCamera: null,
    };
    this.children = [];
    this.lightMode = false;
    this.canRender = false;
    this.sceneRender = true;
    this.activeCamera = null;
    this.scene = new BABYLON.Scene(this.engine, {
      useGeometryUniqueIdsMap: true,
    });
    proxy.$scene = this.scene;
    const angle = Math.PI * 0.5;
    this.camera = new BABYLON.ArcRotateCamera(
      "EDITOR_CAMERA",
      -angle,
      angle,
      8,
      new BABYLON.Vector3(0, 0.5, 0),
      this.scene
    );
    this.ambientLight = new BABYLON.HemisphericLight(
      "EDITOR_LIGHT",
      new BABYLON.Vector3(0, 1, 0),
      this.scene
    );
    this.transform = new Transform(this.scene, this.camera, this.canvas);
    this.grid = new Grid(this);
    this.event = new EventManager(this);
    this.giz = new GizManager(this, cache);
    this.svg = new SvgMesh(this, cache);
    this.setCamera(this.camera);
    this.setCameraController(true, this.camera);
    this.setScene();
    this.render();
    this.cameraLook();
    NameExtra(this.ambientLight);
    this.__cameraNavControls();
    this.addIgnorable(this.camera);
    this.addIgnorable(this.ambientLight);
    // this.addSunLight(new REAL.SunLight(this.scene))
    StartScene(this).then();
    // StartScene;
  }

  addIgnorable(item) {
    if (!this.modelData.ignoreList.includes(item))
      this.modelData.ignoreList.push(item);
  }

  __cameraNavControls() {
    const camera = this.camera;
    // camera.panningAxis = new BABYLON.Vector3(1, 0, 1); // Allow panning along X and Z axes only
    // camera.panningSensibility = 100; // Increase panning sensitivity
    // camera.wheelPrecision = 10; // Adjust zoom precision

    camera.angularSensibilityX = 500; // Adjust rotation sensitivity (higher values for faster rotation)
    camera.angularSensibilityY = 500;
    camera.wheelPrecision = 50; // Adjust zoom precision (lower values for smoother zooming)
    // Enable inertia for smoother movement
    camera.inertia = 0.9; // Adjust inertia value (higher values for longer movement after release)
  }

  async open(buffers, setProgress, setError) {
    // console.log(buffers, setProgress, setError);
    const sceneProgress = (progress) => {
      console.log(progress);
    };
    const sceneErrors = (errors) => {
      setError && setError(errors);
      // let msg = "";
      // for (const error of errors) {
      //   msg += error.toString();
      // }
      // setError && setError(msg);
    };

    const scene = this.scene;
    const realScene = await REAL.LoadScene(
      scene,
      buffers,
      sceneProgress,
      sceneErrors
    );
    console.log("LOAD", realScene);
    if (!realScene) return;
    const children = realScene.children;
    const sunLights = realScene.sunLights;
    const areaLights = realScene.areaLights;
    const spotLights = realScene.spotLights;
    const cameras = realScene.cameras;
    const pointLights = realScene.pointLights;
    for (const camera of cameras) {
      camera.aspect = window.innerWidth / window.innerHeight;
      await this.addCamera(camera, camera.name === "REAL_EYE");
    }
    for (const child of sunLights) await this.addSunLight(child);
    for (const child of spotLights) await this.addSpotLight(child);
    for (const child of pointLights) this.addPointLight(child);
    for (const child of areaLights) this.addAreaLight(child);
    for (const child of [...children]) this.addToGiz(child);
    // console.log(children);
    this.transform.deselectPicked();
  }

  loadModel(model) {
    const icon = createBoundingBox(model, this.scene);
    if (!icon) return;
    this.addToGiz(icon);
    return icon;
  }

  async addCamera(camera, isDefault) {
    camera.detachControl();

    let pos = new BABYLON.Vector3();
    let rot = new BABYLON.Quaternion();
    camera.getWorldMatrix().decompose(null, rot, pos);

    // const pos = new BABYLON.Vector3().copyFrom(camera.position);
    // let rot = new BABYLON.Vector3().copyFrom(camera.rotation);
    // if (camera.rotationQuaternion) {
    //   rot = camera.rotationQuaternion.toEulerAngles();
    // }
    camera.position.set(0, 0, 0);
    camera.rotation.set(0, 0, 0);
    camera.rotationQuaternion = undefined;

    const gizMos = await createCameraGiz(camera, this);
    if (isDefault) {
      if (this.modelData.modelCamera)
        this.modelData.modelCamera.name = "CAMERA";
      camera.name = "REAL_EYE";
      this.modelData.modelCamera = camera;
    } else camera.name = "CAMERA";
    // this.add(gizMos);
    this.addToGiz(gizMos);
    gizMos.position.copyFrom(pos);
    // gizMos.rotation.copyFrom(rot);
    gizMos.rotationQuaternion = rot;
    return gizMos;
  }

  addAreaLight(light) {
    createAreaGiz(light, this);
    this.addToGiz(light);
    return light;
  }

  addPointLight(light) {
    const giz = createPointGiz(light, this);
    this.addToGiz(giz);
    this.__saveModelLight(light);
    return giz;
  }

  async addSpotLight(light) {
    const giz = await createSpotGiz(light, this);
    this.addToGiz(giz);
    this.__saveModelLight(light);
    return giz;
  }

  async addSunLight(light) {
    const sunGiz = await createSunGiz(light, this);
    this.addToGiz(sunGiz.icon);
    this.__saveModelLight(light);
    return sunGiz.icon;
  }

  __saveModelLight(light) {
    this.modelData.modelLights.push(light);
    light.setEnabled(this.lightMode);
  }

  addToGiz(child, addChild = true) {
    if (addChild) this.addSceneChild(child);
    this.transform.addClickableObject(child);
    this.transform.setAsPicked(child);
  }

  addSceneChild(child) {
    if (!this.children.includes(child)) this.children.push(child);
  }

  refreshSceneChild() {
    this.children = this.children.filter(
      (child) => child && !child.isDisposed()
    );
  }

  setControlRender(canRender) {
    this.canRender = canRender;
  }

  setSceneRender(sceneRender) {
    this.sceneRender = sceneRender;
  }

  getCanvas() {
    const canvases = document.querySelectorAll("canvas");
    for (const canvas of canvases)
      if (canvas.id === "renderCanvas") return canvas;
    const canvas = document.createElement("canvas");
    canvas.id = "renderCanvas";
    document.body.appendChild(canvas);
    return canvas;
  }

  setCamera(camera) {
    if (!camera) return;
    this.activeCamera = camera;
    this.scene.activeCamera = camera;
    // this.setCameraController(true, camera);
  }

  switchCamera(camera) {
    if (this.activeCamera === camera) return;
    this.enableCameraGiz(camera);
    this.setCamera(camera);
  }

  enableCameraGiz(camera) {
    if (!isCamera(camera)) return;
    const main_camera = camera.name === "EDITOR_CAMERA";

    function getGiz(camera) {
      if (!camera) return;
      const metadata = camera.metadata;
      if (!metadata) return;
      return metadata.gizGroup;
    }

    function enableGiz(gizGroup, value) {
      if (!gizGroup) return;
      gizGroup.setEnabled(value);
    }

    const gizGroup = getGiz(main_camera ? this.activeCamera : camera);
    enableGiz(gizGroup, main_camera);
    // if(main_camera) this.transform.deselectPicked();
    // this.setCameraController(main_camera, this.camera);
    if (main_camera) {
      camera.detachControl();
      this.camera.attachControl(this.canvas, true);
    } else this.camera.detachControl();
  }

  changePreview(lightMode) {
    this.lightMode = lightMode;
    const modelLights = this.modelData.modelLights;
    this.ambientLight.setEnabled(!lightMode);
    for (const modelLight of modelLights) {
      modelLight.setEnabled(lightMode);
    }
  }

  add(item) {
    // item.parent = this.scene;
    this.scene.addMesh(item);
  }

  attach(child) {
    if (!child.getAbsolutePosition) return;
    // Save child's world space properties
    const worldPosition = child.getAbsolutePosition().clone();
    const worldRotation = child.getAbsolutePivotPoint().clone();
    const worldScaling = child.scaling.clone();

    // Detach child from its current parent (if any)
    if (child.parent) {
      child.parent.removeChild(child);
    }

    // Attach child to the scene
    this.scene.addMesh(child);

    // Restore child's world space properties
    child.setPivotMatrix(
      BABYLON.Matrix.Translation(
        -worldRotation.x,
        -worldRotation.y,
        -worldRotation.z
      )
    );
    child.position = worldPosition;
    child.scaling = worldScaling;
    child.computeWorldMatrix(true);
  }

  removeParent(item) {
    item.parent = null;
  }

  setScene() {
    const camera = this.camera;
    camera.name = "EDITOR_CAMERA";
    this.camera.layerMask = 0b11;
    this.scene.clearColor = this.background;

    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize.bind(this), false);
  }

  render() {
    const scene = this.scene;
    const engine = this.engine;
    engine.runRenderLoop(() => {
      if (this.sceneRender) scene.render();
    });
  }

  cameraLook() {
    const target = new BABYLON.Vector3(2, 2.5, -5);
    this.camera.position.copyFrom(target);
    this.canRender = true;
  }

  setCameraController(enabled, camera = undefined) {
    const activeCamera = camera ? camera : this.activeCamera;
    activeCamera.attachControl(this.canvas, true);
  }

  onWindowResize() {
    this.engine.resize();
    const canvas = this.canvas;
    const camera = this.activeCamera;
    const width = window.innerWidth;
    const height = window.innerHeight;
    camera.aspect = width / height;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }
}

export function isCamera(child) {
  if (!child) return false;
  return child._isCamera;
}
