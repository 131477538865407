import r3dIcon from "@/assets/img/r3d.svg";
import sunIcon from "@/assets/img/sun.svg";
import addIcon from "@/assets/img/add.svg";
import jobIcon from "@/assets/img/jobs.svg";
import usrIcon from "@/assets/img/user.svg";
import openIcon from "@/assets/img/open.svg";
import bulbIcon from "@/assets/img/bulb.svg";
import saveIcon from "@/assets/img/save.svg";
import backIcon from "@/assets/img/back.svg";
import expIcon from "@/assets/img/export.svg";
import impIcon from "@/assets/img/import.svg";
import camIcon from "@/assets/img/camera.svg";
import matIcon from "@/assets/img/globe1.svg";
import arrowIcon from "@/assets/img/arrow.svg";
import lampIcon from "@/assets/img/z_axis.svg";
import cloneIcon from "@/assets/img/clone.svg";
import scaleIcon from "@/assets/img/scale.svg";
import lightIcon from "@/assets/img/globe2.svg";
import renderIcon from "@/assets/img/render.svg";
import rotateIcon from "@/assets/img/rotate.svg";
import spotIcon from "@/assets/img/spotlight.svg";
import translateIcon from "@/assets/img/move.svg";
import transform from "@/assets/img/transform.svg";
import consoleIcon from "@/assets/img/console.svg";
import pointIcon from "@/assets/img/pointlight.svg";
import closeIcon from "@/assets/img/left-close.svg";
import revArrowIcon from "@/assets/img/arrow_rv.svg";

export class IconsUI {
  constructor() {
    this.r3dIcon = r3dIcon;
    this.jobIcon = jobIcon;
    this.addIcon = addIcon;
    this.usrIcon = usrIcon;
    this.matIcon = matIcon;
    this.sunIcon = sunIcon;
    this.expIcon = expIcon;
    this.impIcon = impIcon;
    this.camIcon = camIcon;
    this.openIcon = openIcon;
    this.saveIcon = saveIcon;
    this.lampIcon = lampIcon;
    this.backIcon = backIcon;
    this.bulbIcon = bulbIcon;
    this.spotIcon = spotIcon;
    this.pointIcon = pointIcon;
    this.arrowIcon = arrowIcon;
    this.lightIcon = lightIcon;
    this.scaleIcon = scaleIcon;
    this.cloneIcon = cloneIcon;
    this.closeIcon = closeIcon;
    this.renderIcon = renderIcon;
    this.rotateIcon = rotateIcon;
    this.consoleIcon = consoleIcon;
    this.transformIcon = transform;
    this.revArrowIcon = revArrowIcon;
    this.translateIcon = translateIcon;
  }
}
