import * as BABYLON from "babylonjs";
import EventBus from "@/utils/EventBus";
import { NameExtra } from "@/render_utils/three_tools/gizmos_tools/giz_tools";

class Transform {
  constructor(scene, camera, canvas) {
    this.start = 0;
    this._states = [];
    this.scene = scene;
    this.camera = camera;
    this.canvas = canvas;
    this.clickList = [];
    this._islocal = true;
    this.isActiveTrans = false;
    this.gizmoManager = new BABYLON.GizmoManager(scene);
    NameExtra(this.gizmoManager);
    this.utilityLayer = new BABYLON.UtilityLayerRenderer(scene);
    this.overlayColor = new BABYLON.Color3(1, 0.98, 0);
    this.gizmoManager.attachToMesh(null);
    this.gizmoManager.scaleRatio = 1.0;
    this.gizmoManager.usePointerToAttachGizmos = false;
    this.gizmoManager.positionGizmoEnabled = true;
    this.gizmoManager.rotationGizmoEnabled = true;
    this.gizmoManager.scaleGizmoEnabled = true;
    this.gizmoManager.boundingBoxGizmoEnabled = true;
    this.gizmoManager.snapDistance = 0;
    this.gizmoManager.isRotationSnapEnabled = true;
    this.gizmoManager.rotationSnap = Math.PI / 16;
    this.gizmoManager.scaleSnap = 0.25;
    this.__addEvents();
  }

  __addEvents() {
    this.canvas.addEventListener(
      "pointerdown",
      this.mouseDown.bind(this),
      false
    );
    this.canvas.addEventListener("pointerup", this.mouseUp.bind(this), false);

    const onDrag = this.onDrag.bind(this);
    const onDragEnd = this.onDragEnd.bind(this);
    const gizmoManager = this.gizmoManager;

    gizmoManager.gizmos.positionGizmo.onDragStartObservable.add(() => {
      this.onDragStart("position");
    });
    gizmoManager.gizmos.positionGizmo.onDragObservable.add(onDrag);
    gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(onDragEnd);

    gizmoManager.gizmos.rotationGizmo.onDragStartObservable.add(() => {
      this.onDragStart("rotation");
    });
    gizmoManager.gizmos.rotationGizmo.onDragObservable.add(onDrag);
    gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(onDragEnd);

    gizmoManager.gizmos.scaleGizmo.onDragStartObservable.add(() => {
      this.onDragStart("scaling");
    });
    gizmoManager.gizmos.scaleGizmo.onDragObservable.add(onDrag);
    gizmoManager.gizmos.scaleGizmo.onDragEndObservable.add(onDragEnd);
  }

  mouseDown() {
    this.start = new Date();
    this.isActiveTrans = false;
  }

  mouseUp(e) {
    this.handleClick(e);
  }

  onDragStart(mode) {
    this.isActiveTrans = true;
    this.setState(mode);
  }

  onDrag() {
    this.isActiveTrans = true;
    this.setUIValues();
  }

  onDragEnd() {
    this.isActiveTrans = false;
  }

  setUIValues() {
    const obj = this.getSelected();
    if (!obj) return;
    const gizmo = this.gizmoManager;
    EventBus.emit("setTransformObj", {
      mesh: obj,
      transform: {
        scaling: gizmo.scaleGizmoEnabled,
        position: gizmo.positionGizmoEnabled,
        rotation: gizmo.rotationGizmoEnabled,
      },
      position: obj.position,
      rotation: obj.rotation,
      scaling: obj.scaling,
    });
  }

  handleClick(event) {
    event.preventDefault();
    if (this.isActiveTrans || this.mouseTime() > 0.25) return;
    const scene = this.scene;
    const picked = scene.pick(event.clientX, event.clientY, (mesh) => {
      return this.clickList.includes(mesh);
    });
    if (picked.hit) this.setAsPicked(picked.pickedMesh);
    else this.deselectPicked();
  }

  mouseTime() {
    return (new Date() - this.start) / 1000;
  }

  getSelected() {
    return this.gizmoManager.attachedMesh;
  }

  setState(action) {
    const picked = this.getSelected();
    if (!picked) return;
    const value = picked[action];
    const oldValue = value ? value.clone() : value;
    const state = {
      picked: picked,
      action: action,
      value: oldValue,
    };
    if (this._states.length >= 10) this._states.shift();
    this._states.push(state);
  }

  getStates() {
    return this._states;
  }

  setAsPicked(obj) {
    if (!obj || this.getSelected() === obj) return;
    this.deselectPicked();
    const data = obj.metadata || {};
    let selected = obj;
    this.gizmoManager.attachToMesh(selected);
    this.enableOutlines(selected);
    this.setTransformMode("translate");

    const canLight = data.canLight;
    const canClone = data.canClone;
    const canPos = !data.ignorePos;
    const canScale = !data.ignoreScale;
    const canRotate = !data.ignoreRotate;
    EventBus.emit("setTransformMode", "translate");
    EventBus.emit("setTransformUI", {
      enable: true,
      position: canPos,
      light: canLight,
      clone: canClone,
      scaling: canScale,
      rotation: canRotate,
    });
    EventBus.emit("setTransformObj", {
      mesh: selected,
      position: selected.position,
      rotation: selected.rotation,
      scaling: selected.scaling,
    });
  }

  deselectPicked() {
    if (!this.getSelected()) return;
    this.disableOutlines();
    this.gizmoManager.attachToMesh(null);
    EventBus.emit("setTransformUI", { enable: false });
  }

  setLocalMode(value = false) {
    this._islocal = value;
    const gizmo = this.gizmoManager;
    if (gizmo.scaleGizmoEnabled) {
      gizmo.gizmos.scaleGizmo.updateGizmoPositionToMatchAttachedMesh =
        this._islocal;
    } else if (gizmo.rotationGizmoEnabled) {
      gizmo.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh =
        this._islocal;
    } else if (gizmo.positionGizmoEnabled) {
      gizmo.gizmos.positionGizmo.updateGizmoRotationToMatchAttachedMesh =
        this._islocal;
    }
  }

  setTransformMode(mode) {
    const gizmo = this.gizmoManager;
    this.disableGizmos();
    switch (mode) {
      case "scaling":
        gizmo.scaleGizmoEnabled = true;
        gizmo.gizmos.scaleGizmo.updateGizmoPositionToMatchAttachedMesh =
          this._islocal;
        break;
      case "rotate":
        gizmo.rotationGizmoEnabled = true;
        gizmo.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh =
          this._islocal;
        break;
      case "translate":
        gizmo.positionGizmoEnabled = true;
        gizmo.gizmos.positionGizmo.updateGizmoRotationToMatchAttachedMesh =
          this._islocal;
        break;
    }
  }

  addClickableObject(object) {
    if (!object) return;
    if (this.clickList.includes(object)) return;
    // object.layers.set(1);
    this.clickList.push(object);
    this.gizmoManager.attachableMeshes = this.clickList;
  }

  enableOutlines(mesh) {
    mesh.getChildMeshes().forEach((item) => {
      item.overlayColor = this.overlayColor;
      item.renderOverlay = true;
    });
  }

  disableOutlines() {
    const mesh = this.getSelected();
    if (!mesh) return;
    mesh.getChildMeshes().forEach((item) => {
      item.renderOverlay = false;
      item.overlayColor = undefined;
    });
  }

  enableGizmos() {
    this.utilityLayer.utilityLayerScene.getEngine().setDepthFunction(0); // Disable depth testing for gizmos
    this.utilityLayer.utilityLayerScene.getEngine().setDepthFunction(0); // Disable depth testing for gizmos
    this.gizmoManager.positionGizmoEnabled = true;
    this.gizmoManager.rotationGizmoEnabled = true;
    this.gizmoManager.scaleGizmoEnabled = true;
    this.gizmoManager.boundingBoxGizmoEnabled = true;
  }

  disableGizmos() {
    this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
    this.gizmoManager.scaleGizmoEnabled = false;
    this.gizmoManager.boundingBoxGizmoEnabled = false;
  }
}

export default Transform;
