<template>
  <OptionForm @submit="addLight" @close="$emit('closeMenus')">
    <ColorForm label="Color" :def="color" @onChange="setColor" />
    <InputForm
      label="Intensity"
      type="number"
      :def="intensity"
      @onChange="setIntensity"
    />
    <InputForm label="Angle" type="number" :def="angle" @onChange="setAngle" />
    <InputForm
      label="Distance"
      type="number"
      :def="distance"
      @onChange="setDistance"
    />
    <CheckboxForm
      label="Cast Shadow"
      :def="castShadow"
      @onChange="setCastShadow"
    />
  </OptionForm>
</template>

<script>
import * as BABYLON from "babylonjs";
import * as REAL from "real_api_bbl";
import Scene from "@/render_utils/three_tools/Scene";
import ColorForm from "@/components/form/option/ColorForm.vue";
import InputForm from "@/components/form/option/InputForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";

export default {
  name: "AddSpotLight",
  emits: ["closeMenus", "addSceneItem"],
  components: { InputForm, CheckboxForm, OptionForm, ColorForm },
  props: {
    scene: { type: Scene, required: true },
  },
  data() {
    return {
      color: "#FFFFFF",
      decay: 2.0,
      angle: 30.0,
      penumbra: 0,
      intensity: 10,
      distance: 5.0,
      castShadow: true,
    };
  },
  methods: {
    addLight() {
      this.$emit("closeMenus");
      const scene = this.scene.scene;
      const color = BABYLON.Color3.FromHexString(this.color);
      const angle = BABYLON.Tools.ToRadians(this.angle);
      const light = new REAL.SpotLight(scene, {
        diffuse: color,
        angle: angle,
        decay: this.decay,
        range: this.distance,
        penumbra: this.penumbra,
        intensity: this.intensity,
        shadowEnabled: this.castShadow,
      });
      this.$emit("addSceneItem", light, "spot_light");
    },
    setColor(value) {
      this.color = value;
    },
    setDecay(value) {
      this.decay = value;
    },
    setAngle(value) {
      this.angle = value;
    },
    setPenumbra(value) {
      this.penumbra = value;
    },
    setDistance(value) {
      this.distance = value;
    },
    setCastShadow(value) {
      this.castShadow = value;
    },
    setIntensity(value) {
      this.intensity = value;
    },
  },
};
</script>
