<template>
  <OptionForm @submit="addCamera" @close="$emit('closeMenus')">
    <DropForm
      label="Type"
      :options="['Perspective', 'Orthographic']"
      @onChange="setCameraType"
    />
    <CheckboxForm
      label="Set as default"
      :def="isDefault"
      @onChange="setCameraDef"
    />
    <InputForm label="Near" type="number" :def="near" @onChange="setNear" />
    <InputForm label="Far" type="number" :def="far" @onChange="setFar" />
    <InputForm label="FOV" type="number" :def="fov" @onChange="setFov" />
  </OptionForm>
</template>

<script>
import * as BABYLON from "babylonjs";
import Scene from "@/render_utils/three_tools/Scene";
import DropForm from "@/components/form/option/DropForm.vue";
import InputForm from "@/components/form/option/InputForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";

export default {
  name: "AddCamera",
  emits: ["closeMenus", "addSceneItem"],
  props: {
    scene: { type: Scene, required: true },
  },
  components: { InputForm, CheckboxForm, DropForm, OptionForm },
  data() {
    return {
      cameraType: "perspective",
      isDefault: true,
      near: 0.1,
      far: 100,
      fov: 75,
      aspect: window.innerWidth / window.innerHeight,
    };
  },
  methods: {
    addCamera() {
      const scene = this.scene.scene;
      let camera;
      if (this.cameraType === "perspective") {
        camera = new BABYLON.FreeCamera(
          "perspectiveCamera",
          new BABYLON.Vector3(0, 0, 0),
          scene
        );
        camera.fov = this.fov / 10.0;
      } else if (this.cameraType === "orthographic") {
        let frustumSize = 2;
        let aspect = this.aspect;
        const angle = Math.PI * 0.5;
        // camera = new BABYLON.FreeCamera("orthographicCamera", new BABYLON.Vector3(0, 0, 0), scene);
        camera = new BABYLON.ArcRotateCamera(
          "Camera",
          -angle,
          angle,
          8,
          new BABYLON.Vector3(0, 0.5, 0),
          scene
        );
        camera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
        camera.orthoTop = frustumSize / 2;
        camera.orthoBottom = -frustumSize / 2;
        camera.orthoLeft = (frustumSize * aspect) / -2;
        camera.orthoRight = (frustumSize * aspect) / 2;
      }
      this.$emit("addSceneItem", camera, "camera", this.isDefault);
      this.$emit("closeMenus");
    },
    setFar(value) {
      this.far = value;
    },
    setFov(value) {
      this.fov = value;
    },
    setNear(value) {
      this.near = value;
    },
    setCameraDef(value) {
      this.isDefault = value;
    },
    setCameraType(value) {
      this.cameraType = String(value).toLowerCase();
    },
  },
};
</script>
