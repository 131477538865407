import * as BABYLON from "babylonjs";

export default class GltfImporter {
  constructor(scene, path = "", onProgress = undefined) {
    BABYLON.DracoCompression.Configuration = {
      decoder: {
        wasmUrl: "/draco/draco_wasm_wrapper_gltf.js",
        wasmBinaryUrl: "/draco/draco_decoder_gltf.wasm",
        fallbackUrl: "/draco/draco_decoder_gltf.js",
      },
    };
    this._path = path;
    this._scene = scene;
    this._onProgress = onProgress;
    this._loader = BABYLON.SceneLoader;
  }

  async import() {
    const path = this._path;
    const scene = this._scene;
    const loader = this._loader;
    const gltf = await loader.ImportMeshAsync(
      "",
      path,
      "",
      scene,
      (evt) => {
        this.setProgress(evt);
      }
      // ".glb",
    );
    return this._setGltfScene(gltf);
  }

  async importFromBuffer(isPickable = true, mergeMeshes = false) {
    const scene = this._scene;
    const path = this._path;
    try {
      const gltf = await BABYLON.SceneLoader.ImportMeshAsync(
        "",
        path,
        "",
        scene,
        undefined,
        ".glb"
      );
      URL.revokeObjectURL(path); // Clean up the blob URL
      const parent = this._setGltfScene(gltf);
      const meshes = parent.getChildMeshes();
      for (const mesh of meshes) mesh.isPickable = isPickable;
      if (mergeMeshes) {
        this._mergeMeshes(parent, meshes);
      }
      return parent;
    } catch (error) {
      console.error("Error loading from cache:", error);
      return null;
    }
  }

  _mergeMeshes(parent, meshes) {
    if (!parent || !meshes || meshes.length < 2) return;
    const mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, true);
    // console.log("mergedMesh", mergedMesh)
    if (!mergedMesh) return;
    mergedMesh.setParent(parent);
    return mergedMesh;
  }

  _setGltfScene(gltf) {
    const scene = this._scene;
    const meshes = gltf.meshes;
    const roots = meshes.filter((mesh) => mesh.name === "__root__");
    const parent =
      roots && roots.length
        ? roots[0]
        : new BABYLON.TransformNode("REAL_GIZ_ICON", scene);
    for (const mesh of meshes) {
      if (mesh === parent) continue;
      mesh.setParent(parent);
    }
    // console.error(gltf);
    return parent;
  }

  setProgress(progress) {
    const onProgress = this._onProgress;
    onProgress && onProgress(progress);
  }
}
