import MeshImporter from "@/render_utils/three_tools/import_tools/mesh_importer";

export const ValidExt = [
    "glb", "obj", "gltf", "fbx", "stl", // "blend",
]

export function GetFileInfo(file) {
    const fileSize = Math.round(file.size / (1024*1024));
    const fileName = file.name;
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    return {
        name: fileName,
        size: fileSize,
        ext: String(fileExtension).toLowerCase()
    }
}

export async function importModelFile(file, fileInfo, scene) {
    if (!file || !fileInfo) return;
    const importer = new MeshImporter(file, fileInfo, scene.scene);
    await importer.import();
}
