<template>
  <div>
    <LoadingView :loading="showLoad" />
    <ProgressView v-if="sceneToggle.progress" :progress="progress" />
    <JobUI :icons="icons" :jobs="jobs" v-if="isOnline" />
    <!--    <TestPage v-if="viewScene" :scene="scene" />-->
    <div v-if="viewScene">
      <SceneGui
        v-if="scene"
        :icons="icons"
        :cred="cred"
        :scene="scene"
        :cache="cache"
        :product="product"
        :request="request"
        :isOnline="isOnline"
        :transObj="transObj"
        :userData="userData"
        :transform="transform"
        :sceneToggle="sceneToggle"
        @setCamera="setCamera"
        @renderScene="renderScene"
        @addSceneItem="addSceneItem"
        @setLocalMode="setLocalMode"
        @setSceneToggle="setSceneToggle"
        @setTransformMode="setTransformMode"
      />
    </div>
  </div>
</template>

<script>
import EventBus from "@/utils/EventBus";
import { IconsUI } from "@/utils/IconsUI";
import { RenderJob } from "@/tools/job_tools";
import CacheManager from "@/utils/CacheManager";
import JobUI from "@/components/render/JobUI.vue";
import SocketManager from "@/utils/SocketManager";
import CryptoManager from "@/utils/CryptoManager";
import Scene from "@/render_utils/three_tools/Scene";
import { RequestManager } from "@/utils/RequestManager";
import SceneGui from "@/components/render/SceneGui.vue";
import LoadingView from "@/components/web/LoadingView.vue";
import ProgressView from "@/components/web/ProgressView.vue";
import RealExporter from "@/render_utils/three_tools/export_tools/RealExporter";
// import TestPage from "@/pages/TestPage.vue";

export default {
  name: "RenderPage",
  components: {
    JobUI,
    // TestPage,
    SceneGui,
    LoadingView,
    ProgressView,
  },
  props: {
    cred: {
      type: Object,
      required: true,
    },
    cache: {
      type: CacheManager,
      required: true,
    },
    encKey: {
      type: String,
      required: true,
    },
    crypto: {
      type: CryptoManager,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    socket: {
      type: SocketManager,
      required: true,
    },
    request: {
      type: RequestManager,
      required: true,
    },
  },
  data() {
    return {
      progress: 0,
      jobs: [],
      showLoad: false,
      isOnline: false,
      scene: undefined,
      viewScene: false,
      sceneToggle: {
        light: false,
        camera: false,
        render: false,
        export: false,
        isLocal: true,
        progress: false,
      },
      transform: {
        enable: false,
        clone: false,
        light: false,
        scaling: true,
        rotation: true,
        position: true,
      },
      product: undefined,
      transObj: undefined,
      exporter: undefined,
      icons: new IconsUI(),
    };
  },
  methods: {
    async start() {
      this.request.setProgress(this.setProgress);
      this.scene = new Scene(this.cache);
      this.exporter = new RealExporter(
        this.scene,
        this.setProgress,
        this.showProgress,
        this.setLoading
      );
      this.viewScene = true;
      this.jobs = [];
    },
    setLoading(enable) {
      this.showLoad = enable;
    },
    showProgress(enable) {
      if (enable) this.progress = 0;
      this.sceneToggle.progress = enable;
    },
    setProgress(progress) {
      this.progress = progress;
    },
    setError(msg) {
      EventBus.emit("setError", msg);
    },
    async renderScene() {
      if (this.sceneToggle.render)
        return EventBus.emit(
          "setError",
          "Please wait for previous job to done!"
        );
      this.setSceneToggle("render", true);
      const realScene = await this.exporter.export("r3d", {}, true);
      console.error("realScene", realScene);
      await RenderJob(realScene, this);
      this.setSceneToggle("render", false);
    },
    async exportScene(export_info) {
      const { ext, options } = export_info;
      await this.exporter.export(ext, options);
    },
    async saveScene() {
      await this.exporter.export("r3d");
      // await this.exporter.export("glb");
      // await this.exporter.export("gltf");
    },
    async openScene(fileData) {
      // const dec = this.crypto.decrypt(fileData, this.encKey);
      // this.scene.open(dec);
      // console.log(fileData)
      this.showProgress(true);
      await this.scene.open(fileData, this.setProgress, this.setError);
      this.showProgress(false);
    },
    async addSceneItem(item, type, info) {
      const scene = this.scene;
      switch (type) {
        case "camera":
          await scene.addCamera(item, info);
          break;
        case "area_light":
          scene.addAreaLight(item);
          break;
        case "sun_light":
          await scene.addSunLight(item);
          break;
        case "spot_light":
          await scene.addSpotLight(item);
          break;
        case "point_light":
          scene.addPointLight(item);
          break;
      }
    },
    setSceneToggle(type, value) {
      this.sceneToggle[type] = value;
    },
    setCamera(toSelected) {
      const mainCamera = this.scene.camera;
      this.scene.setCameraController(!toSelected, mainCamera);
      const curObj = toSelected
        ? this.scene.transform.getSelected().metadata.camera
        : mainCamera;
      if (!curObj) return;
      this.scene.switchCamera(curObj);
    },
    setLocalMode(value) {
      this.scene.transform.setLocalMode(value);
    },
    setTransformMode(mode) {
      this.scene.transform.setTransformMode(mode);
    },
    async loadModel(modelInfo) {
      const root = modelInfo.model;
      this.scene.loadModel(root);

      const sunLights = modelInfo.sunLights;
      const realLights = modelInfo.realLights;
      const spotLights = modelInfo.spotLights;
      const pointLights = modelInfo.pointLights;
      for (const light of sunLights) {
        await this.scene.addSunLight(light);
      }
      for (const light of spotLights) {
        this.scene.addSpotLight(light);
      }
      for (const light of pointLights) {
        this.scene.addPointLight(light);
      }
      for (const light of realLights) {
        const lName = light.name;
        switch (lName) {
          case "REAL_AREA_LIGHT":
            // TODO: Parse to Real Area Light
            // this.scene.addAreaLight(light);
            break;
        }
      }
    },
    setUserStatus(status) {
      this.isOnline = status === 1;
    },
    setTransformUI(transform) {
      if (this.sceneToggle.camera) return;
      const enable = transform.enable;
      if (!enable) {
        const keys = Object.keys(this.transform);
        for (const key of keys) {
          transform[key] = false;
        }
      }
      this.transform = transform;
    },
    setTransformObj(obj) {
      if (!obj) return;
      this.transObj = obj;
    },
    updateJob(job) {
      // console.error("JOB", job);
      const jobID = job.jobID;
      const count = this.jobs.length;
      if (count === 0) {
        this.jobs = [job];
        return;
      }
      for (let i = 0; i < count; i++) {
        if (this.jobs[i].jobID !== jobID) continue;
        this.jobs[i] = job;
        return;
      }
      this.jobs.push(job);
    },
    updateJobs(jobs) {
      this.jobs = jobs;
    },
  },
  async mounted() {
    await this.start();
  },
  beforeMount() {
    EventBus.on("saveScene", this.saveScene);
    EventBus.on("updateJob", this.updateJob);
    EventBus.on("updateJobs", this.updateJobs);
    EventBus.on("openScene", this.openScene);
    EventBus.on("modelLoaded", this.loadModel);
    EventBus.on("setLoading", this.setLoading);
    EventBus.on("exportScene", this.exportScene);
    EventBus.on("userStatus", this.setUserStatus);
    EventBus.on("setTransformUI", this.setTransformUI);
    EventBus.on("setTransformObj", this.setTransformObj);
  },
  beforeUnmount() {
    EventBus.off("saveScene", this.saveScene);
    EventBus.off("updateJob", this.updateJob);
    EventBus.off("updateJobs", this.updateJobs);
    EventBus.off("openScene", this.openScene);
    EventBus.off("modelLoaded", this.loadModel);
    EventBus.off("setLoading", this.setLoading);
    EventBus.off("exportScene", this.exportScene);
    EventBus.off("userStatus", this.setUserStatus);
    EventBus.off("setTransformUI", this.setTransformUI);
    EventBus.off("setTransformObj", this.setTransformObj);
  },
};
</script>
