import * as BABYLON from "babylonjs";
import {NameChildGiz, NameGiz} from "@/render_utils/three_tools/gizmos_tools/giz_tools";

export function createPointGiz(light, bblScene) {
    const scene = bblScene.scene;
    const radius = 0.05;
    const frustum = lightShineIcon(radius*2);
    const sphereMaterial = new BABYLON.StandardMaterial("gizMaterial", scene);
    sphereMaterial.diffuseColor = BABYLON.Color3.FromHexString("#1ca9e6");
    const icon = BABYLON.MeshBuilder.CreateSphere("REAL_POINT_LIGHT", { diameter: radius, segments: 32 }, scene);
    icon.material = sphereMaterial;

    const gizGroup = new BABYLON.TransformNode("REAL_GIZ", scene);
    frustum.setParent(gizGroup);
    gizGroup.setParent(icon);

    light.name = "REAL_POINT_LIGHT"
    light.parent = icon;

    NameGiz(frustum);
    NameGiz(gizGroup);
    NameChildGiz(icon);

    icon.metadata = {
        canLight: true,
        ignoreScale: true,
        ignoreRotate: true,
        childID: light.uniqueId,
        type: "REAL_POINT_LIGHT",
    };

    light.metadata = {type: "REAL_POINT_LIGHT"}
    bblScene.addIgnorable(icon);
    bblScene.addIgnorable(frustum);
    bblScene.addIgnorable(gizGroup);

    return icon;
}

export function lightShineIcon(radius, scene, numLines=8) {
    const gizGroup = new BABYLON.TransformNode("REAL_GIZ_LINES", scene);

    for (let i = 0; i < numLines; i++) {
        const angle = (i / numLines) * Math.PI * 2;
        const x = Math.cos(angle) * radius;
        const y = Math.sin(angle) * radius;
        const z = 0;

        const points = [
            new BABYLON.Vector3(0, 0, 0),
            new BABYLON.Vector3(x, y, z)
        ];

        const line = BABYLON.MeshBuilder.CreateLines(`line${i}`, { points: points }, scene);
        line.setParent(gizGroup);
    }

    return gizGroup;
}

