import * as BABYLON from "babylonjs";

export function DynamicLine(meshA, meshB, color, scene) {
  const positionA = meshA.getAbsolutePosition();
  const positionB = meshB.getAbsolutePosition();
  const points = [positionA, positionB];
  const lineMesh = BABYLON.MeshBuilder.CreateLines(
    "REAL_GIZ_LINE",
    {
      points: points,
      updatable: true,
    },
    scene
  );
  lineMesh.color = color;
  return lineMesh;
}

export function DynamicLineFrame(meshA, meshB, color, scene) {
  const positionA = meshA.getAbsolutePosition();
  const positionB = meshB.getAbsolutePosition();

  let point1 = new BABYLON.Vector3();
  let point2 = new BABYLON.Vector3();
  let points = [point1, point2];
  let line = BABYLON.MeshBuilder.CreateLines(
    "REAL_GIZ_LINE",
    { points: points, updatable: true },
    scene
  );
  line.color = color;

  const drawLine = () => {
    if (point1.equals(positionA) && point2.equals(positionB)) return;
    point1 = new BABYLON.Vector3().copyFrom(positionA);
    point2 = new BABYLON.Vector3().copyFrom(positionB);
    points = [point1, point2];
    line = BABYLON.MeshBuilder.CreateLines(
      "REAL_GIZ_LINE",
      { points: points, updatable: true, instance: line },
      scene
    );
    line.color = color;
  };
  scene.registerAfterRender(drawLine);
  return line;
}
