export default class EventManager {
  constructor(scene) {
    this._scene = scene;
    window.addEventListener("keydown", this.handleKeyDown.bind(this), false);
  }

  async handleKeyDown(e) {
    if (e.ctrlKey && e.key === "z") this.undo();
    else if (e.key === "Delete") this.delete();
  }

  delete() {
    const picked = this._getSelected();
    if (!picked) return;
    const metaData = this._getData(picked);
    const depends = metaData.depends;

    if (depends && depends.length !== 0) {
      for (const depend of [...depends]) {
        if (depend) depend.dispose();
      }
    }

    this._deselectPicked();
    picked.dispose();
    this._scene.refreshSceneChild();
  }

  undo() {
    const transform = this._scene.transform;
    const states = transform.getStates();
    if (!states || states.length === 0) return;
    const state = states.pop();
    const picked = state.picked;
    if (!picked || picked.isDisposed()) return;
    const value = state.value;
    const action = state.action;
    if (!action || !value) return;
    picked[action] = value;
    transform.setUIValues();
  }

  _getSelected() {
    return this._scene.transform.getSelected();
  }

  _deselectPicked() {
    return this._scene.transform.deselectPicked();
  }

  _getData(obj) {
    return obj.metadata || {};
  }
}
