import * as BABYLON from "babylonjs";
import EventBus from "@/utils/EventBus";
import "babylonjs-loaders/babylon.objFileLoader.min"
import "babylonjs-loaders/babylon.stlFileLoader.min"
import "babylonjs-loaders/babylon.glTFFileLoader.min"
import "babylonjs-loaders/babylon.glTF1FileLoader.min"
import "babylonjs-loaders/babylon.glTF2FileLoader.min"
import {
    isSpotLight,
    isPointLight,
    isDirectionalLight, isRealLight
} from "@/render_utils/three_tools/creation_tools/mesh_tools";
import {NameImport} from "@/render_utils/three_tools/gizmos_tools/giz_tools";

export default class MeshImporter{
    constructor(file, fileInfo, scene, onProgress=undefined) {
        BABYLON.DracoCompression.Configuration = {
            decoder: {
                wasmUrl: "/draco/draco_wasm_wrapper_gltf.js",
                wasmBinaryUrl: "/draco/draco_decoder_gltf.wasm",
                fallbackUrl: "/draco/draco_decoder_gltf.js",
            },
        };
        this.file = file;
        this.scene = scene;
        this.fileInfo = fileInfo;
        this.onProgress = onProgress;
        this.loader = BABYLON.SceneLoader;
    }
    async import() {
        const gltf = await this.loader.ImportMeshAsync(
            "",
            "",
            this.file,
            this.scene,
            (evt) => {this.setProgress(evt)},
            // ".glb",
        );
        // const meshes = gltf.transformNodes;
        const meshes = gltf.meshes;
        const roots = meshes.filter(mesh => mesh.name === "__root__");
        const parent = (roots && roots.length) ? roots[0] : new BABYLON.TransformNode("REAL_MESH_PARENT", this.scene);
        NameImport(parent)
        const sunLights = [];
        const spotLights = [];
        const realLights = [];
        const pointLights = [];
        for (const mesh of meshes) {
            if(mesh === parent) continue;
            mesh.setParent(parent);
        }
        const lights = gltf.lights;
        for (const light of lights) {
            if(isDirectionalLight(light)) sunLights.push(light);
            else if(isPointLight(light)) spotLights.push(light);
            else if(isSpotLight(light)) pointLights.push(light);
            else if(isRealLight(light.name)) realLights.push(light);
        }
        EventBus.emit('modelLoaded', {
            ext: "gltf",
            model: parent,
            sunLights: sunLights,
            realLights: realLights,
            spotLights: spotLights,
            pointLights: pointLights,
        });
        console.log(gltf);
        return parent;
    }
    setProgress(progress) {
        this.onProgress && this.onProgress(progress);
    }
}
