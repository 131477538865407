import * as BABYLON from "babylonjs";
import {NameEmptyGiz} from "@/render_utils/three_tools/gizmos_tools/giz_tools";
import {calculateBoundingBox} from "@/render_utils/three_tools/creation_tools/mesh_tools";


/**
 * Creates an invisible clickable box around a given object.
 *
 */
export function createBoundingBox(object, scene) {
    const boundingBox = calculateBoundingBox(object);
    const size = boundingBox.maximum.subtract(boundingBox.minimum);
    const boxMaterial = new BABYLON.StandardMaterial("boxMaterial", scene);
    boxMaterial.alpha = 0;
    const box = BABYLON.MeshBuilder.CreateBox("REAL_EMPTY_GIZ", {
        width: size.x, height: size.y, depth: size.z
    }, scene);
    box.material = boxMaterial;
    NameEmptyGiz(box);
    const center = boundingBox.center;
    box.position.addToRef(center, box.position);
    object.setParent(box);
    return box;
}
