<template>
  <OptionForm @submit="addAreaLight" @close="$emit('closeMenus')">
    <InputForm label="Width" type="number" :def="width" @onChange="setWidth" />
    <InputForm
      label="Height"
      type="number"
      :def="height"
      @onChange="setHeight"
    />
    <ColorForm label="Color" :def="color" @onChange="setColor" />
    <InputForm
      label="Intensity"
      type="number"
      :def="intensity"
      @onChange="setIntensity"
    />
    <InputForm
      label="Beam Angle"
      type="number"
      :def="beamAngle"
      @onChange="setBeam"
    />
    <CheckboxForm
      label="Multiple Importance"
      :def="multipleImportance"
      @onChange="setImportance"
    />
  </OptionForm>
</template>

<script>
import * as BABYLON from "babylonjs";
import * as REAL from "real_api_bbl";
import Scene from "@/render_utils/three_tools/Scene";
import InputForm from "@/components/form/option/InputForm.vue";
import ColorForm from "@/components/form/option/ColorForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";

export default {
  name: "AddAreaLight",
  emits: ["closeMenus", "addSceneItem"],
  components: { InputForm, CheckboxForm, OptionForm, ColorForm },
  props: {
    scene: { type: Scene, required: true },
  },
  data() {
    return {
      width: 1,
      height: 1,
      color: "#FFFFFF",
      intensity: 1,
      beamAngle: 180,
      multipleImportance: true,
    };
  },
  methods: {
    addAreaLight() {
      this.$emit("closeMenus");
      const scene = this.scene.scene;
      const color = BABYLON.Color3.FromHexString(this.color);
      const light = new REAL.AreaLight(scene, {
        name: "REAL_AREA_LIGHT",
        diffuse: color,
        width: this.width,
        height: this.height,
        beamAngle: this.beamAngle,
        intensity: this.intensity,
        shadowEnabled: this.castShadow,
        multipleImportance: this.multipleImportance,
      });
      // light.rotation.y = Math.PI;
      this.$emit("addSceneItem", light, "area_light");
    },
    setColor(value) {
      this.color = value;
    },
    setWidth(value) {
      this.width = value;
    },
    setHeight(value) {
      this.height = value;
    },
    setBeam(value) {
      this.beamAngle = value;
    },
    setIntensity(value) {
      this.intensity = value;
    },
    setImportance(value) {
      this.multipleImportance = value;
    },
  },
};
</script>
