import * as BABYLON from "babylonjs";
import {CreateGrid} from "@/render_utils/three_tools/creation_tools/CreateGrid";

export default class Grid {
    constructor(sceneClass, near= 0.01, far= 10000, mode="dark") {
        const scene = sceneClass.scene;
        this.params = this.getParams(near, far, mode);
        this.root = new BABYLON.TransformNode("REAL_GRID", scene);
        this.mesh = CreateGrid(this.params, scene);
        this.axis = {
            xLine: null,
            zLine: null
        };
        this.createAxis(far, scene);
        this.setRoot();
        sceneClass.addIgnorable(this.root);
        sceneClass.addIgnorable(this.mesh);
        sceneClass.addIgnorable(this.axis.xLine);
        sceneClass.addIgnorable(this.axis.zLine);
    }
    createAxis(far, scene) {
        const space = 0.001;
        const opacity = 0.75;
        const red = new BABYLON.Color3(1, 0, 0);
        const blue = new BABYLON.Color3(0, 0, 1);

        // Define the line materials
        const redMaterial = new BABYLON.StandardMaterial("redMaterial", scene);
        redMaterial.diffuseColor = red;
        redMaterial.alpha = opacity;

        const blueMaterial = new BABYLON.StandardMaterial("blueMaterial", scene);
        blueMaterial.diffuseColor = blue;
        blueMaterial.alpha = opacity;

        // Define the line geometries
        const xGeometry = BABYLON.MeshBuilder.CreateLines("xLine", { points: [new BABYLON.Vector3(-far, 0, 0), new BABYLON.Vector3(far, 0, 0)] }, scene);
        const zGeometry = BABYLON.MeshBuilder.CreateLines("zLine", { points: [new BABYLON.Vector3(0, 0, -far), new BABYLON.Vector3(0, 0, far)] }, scene);

        // Position the lines
        xGeometry.position.y += space;
        zGeometry.position.y += space;

        // Apply materials to the lines
        xGeometry.material = redMaterial;
        zGeometry.material = blueMaterial;

        // Parent the lines to the root
        xGeometry.parent = this.root;
        zGeometry.parent = this.root;

        // Store the lines in axis
        this.axis.xLine = xGeometry;
        this.axis.zLine = zGeometry;
    }

    // createGrid(params, scene) {
    //     const mesh = BABYLON.MeshBuilder.CreateGround("REAL_GRID", {}, scene);
    //     return mesh;
    // }

    getParams(near, far, mode) {
        const spacing = near * far;
        return {
            distance: far,
            opacity: 0.05,
            size1: spacing / 10,
            size2: spacing / 100,
            color: this.getGridColor(mode),
        };
    }
    getGridColor(mode) {
        return mode.toLowerCase() === "dark" ? new BABYLON.Color3(1, 1, 1) : new BABYLON.Color3(0, 0, 0);
    }
    setRoot() {
        this.mesh.name = "REAL_GRID_MESH";
        this.axis.xLine.name = "REAL_GRID_X";
        this.axis.zLine.name = "REAL_GRID_Z";
    }
}
