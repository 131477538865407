<template>
  <OptionForm
    submitName="Save"
    class="backdrop-filter backdrop-blur-md"
    @submit="
      modifyLight();
      $emit('closeMenus');
    "
    @close="$emit('closeMenus')"
  >
    <div v-if="ready">
      <div v-if="isArea()">
        <ColorForm label="Color" :def="color" @onChange="setColor" />
        <InputForm
          label="Beam Angle"
          type="number"
          :def="beamAngle"
          @onChange="setBeam"
        />
        <InputForm
          label="Intensity"
          type="number"
          :def="intensity"
          @onChange="setIntensity"
        />
        <CheckboxForm
          label="Multiple Importance"
          :def="multipleImportance"
          @onChange="setImportance"
        />
      </div>
      <div v-else-if="isSun()">
        <ColorForm label="Color" :def="color" @onChange="setColor" />
        <InputForm
          label="Intensity"
          type="number"
          :def="intensity"
          @onChange="setIntensity"
        />
        <CheckboxForm
          label="Cast Shadow"
          :def="castShadow"
          @onChange="setCastShadow"
        />
      </div>
      <div v-else-if="isSpot()">
        <ColorForm label="Color" :def="color" @onChange="setColor" />
        <!--          <InputForm label="Decay" type="number" :def="decay" @onChange="setDecay" />-->
        <InputForm
          label="Angle"
          type="number"
          :def="angle"
          @onChange="setAngle"
        />
        <!--          <InputForm label="Penumbra" type="number" :def="penumbra" @onChange="setPenumbra" />-->
        <InputForm
          label="Distance"
          type="number"
          :def="distance"
          @onChange="setDistance"
        />
        <InputForm
          label="Intensity"
          type="number"
          :def="intensity"
          @onChange="setIntensity"
        />
        <CheckboxForm
          label="Cast Shadow"
          :def="castShadow"
          @onChange="setCastShadow"
        />
      </div>
      <div v-else-if="isPoint()">
        <ColorForm label="Color" :def="color" @onChange="setColor" />
        <!--          <InputForm label="Decay" type="number" :def="decay" @onChange="setDecay" />-->
        <InputForm
          label="Distance"
          type="number"
          :def="distance"
          @onChange="setDistance"
        />
        <InputForm
          label="Intensity"
          type="number"
          :def="intensity"
          @onChange="setIntensity"
        />
        <CheckboxForm
          label="Cast Shadow"
          :def="castShadow"
          @onChange="setCastShadow"
        />
      </div>
    </div>
  </OptionForm>
</template>
<script>
import * as BABYLON from "babylonjs";
import Scene from "@/render_utils/three_tools/Scene";
import { DegToRad, RadToDeg } from "@/tools/math_tools";
import InputForm from "@/components/form/option/InputForm.vue";
import ColorForm from "@/components/form/option/ColorForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";
import { getMetaData } from "@/render_utils/three_tools/creation_tools/mesh_tools";
import { convertThreeColorToHTML } from "@/render_utils/three_tools/creation_tools/color_tools";

export default {
  name: "ModifyLight",
  components: { ColorForm, InputForm, CheckboxForm, OptionForm },
  props: {
    scene: { type: Scene, required: true },
  },
  data() {
    return {
      ready: false,
      type: undefined,
      light: undefined,
      decay: undefined,
      angle: undefined,
      color: undefined,
      distance: undefined,
      penumbra: undefined,
      intensity: undefined,
      beamAngle: undefined,
      castShadow: undefined,
      multipleImportance: undefined,
    };
  },
  mounted() {
    this.type = this.getPickedType();
    this.light = this.getLightMesh();
    const light = this.light;
    if (!light) return;
    this.color = convertThreeColorToHTML(light.diffuse);
    switch (this.type) {
      case "REAL_AREA_LIGHT":
        this.intensity = light.intensity;
        this.beamAngle = light.beamAngle;
        this.multipleImportance = light.multipleImportance;
        break;
      case "REAL_SPOT_LIGHT":
        this.angle = RadToDeg(light.angle);
        this.decay = light.decay;
        this.penumbra = light.penumbra;
        this.distance = light.range;
        this.intensity = light.intensity;
        this.castShadow = light.shadowEnabled;
        break;
      case "REAL_POINT_LIGHT":
        this.decay = light.decay;
        this.distance = light.range;
        this.intensity = light.intensity;
        this.castShadow = light.shadowEnabled;
        break;
      case "REAL_SUN_LIGHT":
        this.intensity = light.intensity;
        this.castShadow = light.shadowEnabled;
        break;
    }
    this.ready = true;
  },
  methods: {
    modifyLight() {
      const light = this.light;
      if (!light) return;
      if (this.color) light.diffuse = BABYLON.Color3.FromHexString(this.color);
      switch (this.type) {
        case "REAL_SUN_LIGHT":
          light.intensity = this.intensity;
          light.shadowEnabled = this.castShadow;
          break;
        case "REAL_SPOT_LIGHT":
          light.angle = DegToRad(this.angle);
          light.decay = this.decay;
          light.penumbra = this.penumbra;
          light.range = this.distance;
          light.intensity = this.intensity;
          light.shadowEnabled = this.castShadow;
          break;
        case "REAL_POINT_LIGHT":
          light.decay = this.decay;
          light.range = this.distance;
          light.intensity = this.intensity;
          light.shadowEnabled = this.castShadow;
          break;
        case "REAL_AREA_LIGHT":
          light.beamAngle = this.beamAngle;
          light.intensity = this.intensity;
          light.multipleImportance = this.multipleImportance;
          break;
      }
    },
    setColor(value) {
      this.color = value;
    },
    setDecay(value) {
      this.decay = value;
    },
    setAngle(value) {
      this.angle = value;
    },
    setBeam(value) {
      this.beamAngle = value;
    },
    setPenumbra(value) {
      this.penumbra = value;
    },
    setDistance(value) {
      this.distance = value;
    },
    setIntensity(value) {
      this.intensity = value;
    },
    setCastShadow(value) {
      this.castShadow = value;
    },
    setImportance(value) {
      this.multipleImportance = value;
    },
    isSun() {
      const type = this.getPickedType();
      return type === "REAL_SUN_LIGHT";
    },
    isSpot() {
      const type = this.getPickedType();
      return type === "REAL_SPOT_LIGHT";
    },
    isArea() {
      const type = this.getPickedType();
      return type === "REAL_AREA_LIGHT";
    },
    isPoint() {
      const type = this.getPickedType();
      return type === "REAL_POINT_LIGHT";
    },
    getPicked() {
      return this.scene.transform.getSelected();
    },
    getPickedType() {
      const picked = this.getPicked();
      return picked ? getMetaData(picked).type : undefined;
    },
    getLightMesh() {
      const type = this.type;
      const picked = this.getPicked();
      switch (type) {
        case "REAL_AREA_LIGHT":
          return picked;
        default:
          for (const child of this.getChildren(picked)) {
            if (getMetaData(child).type === type) return child;
          }
      }
    },
    getChildren(picked) {
      if (!picked) return [];
      return picked.getChildren();
    },
  },
};
</script>
