<template>
  <div
    class="bg-sc-dark2 rounded-lg info-panel fixed top-1/2 right-0 transform -translate-y-1/2 p-2"
  >
    <div v-if="transObj" class="property">
      <div
        v-for="(propertyType, index) in ['position', 'rotation', 'scaling']"
        :key="index"
      >
        <div v-if="transform[propertyType]" class="property-group">
          <label class="block text-sm font-medium text-gray-300 capitalize">{{
            propertyType
          }}</label>
          <div
            v-for="axis in ['x', 'y', 'z']"
            :key="propertyType + axis"
            class="flex items-center mt-1 mb-2"
          >
            <label class="text-gray-300 uppercase">{{ axis }}</label>
            <input
              type="number"
              :value="getValue(transObj, propertyType, axis)"
              @change="
                updatePickObject(propertyType, axis, $event.target.value)
              "
              class="ml-2 flex-1 appearance-none bg-sc-dark2-l text-white border border-gray-500 py-1 px-2 rounded-md focus:outline-none focus:border-blue-500 text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as BABYLON from "babylonjs";
import { DegToRad, RadToDeg, roundValue } from "@/tools/math_tools";

export default {
  name: "TransformGui",
  props: {
    transObj: { type: Object, required: true },
    transform: { type: Object, required: true },
  },
  methods: {
    getValue(obj, property, axis) {
      const value = obj[property][axis];
      if (property !== "rotation") return roundValue(value);
      const mesh = obj.mesh;
      const isQuat = this._isQuaternion(mesh);
      if (isQuat) {
        const quaternion = mesh.rotationQuaternion.toEulerAngles();
        return RadToDeg(quaternion[axis]);
      }
      return RadToDeg(value);
    },
    updatePickObject(property, axis, value) {
      const obj = this.transObj;
      if (!obj) return;
      if (property === "rotation") {
        const mesh = obj.mesh;
        const angle = DegToRad(value);
        const isQuat = this._isQuaternion(mesh);
        if (isQuat) {
          const quaternion = mesh.rotationQuaternion.toEulerAngles();
          quaternion[axis] = angle;
          mesh.rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(
            quaternion.x,
            quaternion.y,
            quaternion.z
          );
        } else mesh.rotation = this.__getAngleVector(mesh, axis, angle);
      } else obj[property][axis] = parseFloat(value);
    },
    _isQuaternion(mesh) {
      return mesh.name === "REAL_CAM_GIZ" && mesh.rotationQuaternion;
    },
    __getAngleVector(mesh, axis, angle) {
      const rot = mesh.rotation;
      switch (axis) {
        case "x":
          return new BABYLON.Vector3(angle, rot.y, rot.z);
        case "y":
          return new BABYLON.Vector3(rot.x, angle, rot.z);
      }
      return new BABYLON.Vector3(rot.x, rot.y, angle);
    },
  },
};
</script>
