import * as BABYLON from "babylonjs";
import { NameGiz, NameCameraGiz } from "@/render_utils/three_tools/gizmos_tools/giz_tools";

export async function createCameraGiz(camera, bblScene) {
    const scene = bblScene.scene;
    const rectWide = 1;
    const rectHigh = 0.5;
    const triWide = 0.5;
    const rectLength = 2;
    const color = new BABYLON.Color3(1, 1, 1);

    const points = [
        new BABYLON.Vector3(0, 0, 0),
        new BABYLON.Vector3(-rectWide, -rectHigh, rectLength),
        new BABYLON.Vector3(0, 0, 0),
        new BABYLON.Vector3(rectWide, -rectHigh, rectLength),
        new BABYLON.Vector3(0, 0, 0),
        new BABYLON.Vector3(rectWide, rectHigh, rectLength),
        new BABYLON.Vector3(0, 0, 0),
        new BABYLON.Vector3(-rectWide, rectHigh, rectLength),
        new BABYLON.Vector3(0, 0, 0),
    ];

    // const lines = BABYLON.MeshBuilder.CreateLineSystem("frustumLines", {lines: points}, scene);
    const lines = BABYLON.MeshBuilder.CreateLines("rectangleLines", {points: points}, scene);
    lines.color = color;

    // const icon = BABYLON.MeshBuilder.CreateSphere("gizSphere", {diameter: 0.1 * 2}, scene);
    // const material = new BABYLON.StandardMaterial("gizMaterial", scene);
    // material.emissiveColor = color;
    // icon.material = material;
    // material.unlit = true;
    const giz = bblScene.giz;
    const icon = await giz.load("eye");

    const rectanglePoints = [
        new BABYLON.Vector3(-rectWide, -rectHigh, rectLength),
        new BABYLON.Vector3(rectWide, -rectHigh, rectLength),
        new BABYLON.Vector3(rectWide, rectHigh, rectLength),
        new BABYLON.Vector3(-rectWide, rectHigh, rectLength),
        new BABYLON.Vector3(-rectWide, -rectHigh, rectLength),
    ];
    const rectangle = BABYLON.MeshBuilder.CreateLines("rectangleLines", {points: rectanglePoints}, scene);
    rectangle.color = color;

    const trianglePoints = [
        new BABYLON.Vector3(0, triWide, 0),
        new BABYLON.Vector3(-triWide, 0, 0),
        new BABYLON.Vector3(triWide, 0, 0),
        new BABYLON.Vector3(0, triWide, 0),
    ];
    const triangle = BABYLON.MeshBuilder.CreateLines("triangleLines", {points: trianglePoints}, scene);
    triangle.color = color;
    triangle.position.set(0, rectHigh, rectLength);

    // const gizGroup = new BABYLON.Mesh("gizGroup", scene);
    const gizGroup = new BABYLON.TransformNode("REAL_GIZ", scene);
    lines.setParent(gizGroup);
    rectangle.setParent(gizGroup);
    triangle.setParent(gizGroup);
    gizGroup.setParent(icon);
    camera.parent = icon;
    // camera.rotation.y = Math.PI;
    // icon.setParent(camera);

    NameGiz(lines);
    NameGiz(gizGroup);
    NameGiz(triangle);
    NameGiz(rectangle);
    NameCameraGiz(icon);

    camera.metadata = {
        gizGroup: gizGroup,
    }

    icon.metadata = {
        canClone: true,
        ignoreScale: true,
        camera: camera,
        childID: camera.uniqueId,
        type: "REAL_CAM_GIZ"
    };

    bblScene.addIgnorable(icon);
    bblScene.addIgnorable(lines);
    bblScene.addIgnorable(gizGroup);
    bblScene.addIgnorable(triangle);
    bblScene.addIgnorable(rectangle);

    return icon;
}
