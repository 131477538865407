<template>
  <OptionForm @submit="addSunLight" @close="$emit('closeMenus')">
    <ColorForm label="Color" :def="color" @onChange="setColor" />
    <InputForm
      label="Intensity"
      type="number"
      :def="intensity"
      @onChange="setIntensity"
    />
    <CheckboxForm
      label="Cast Shadow"
      :def="castShadow"
      @onChange="setCastShadow"
    />
  </OptionForm>
</template>

<script>
import * as BABYLON from "babylonjs";
import * as REAL from "real_api_bbl";
import Scene from "@/render_utils/three_tools/Scene";
import ColorForm from "@/components/form/option/ColorForm.vue";
import InputForm from "@/components/form/option/InputForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";

export default {
  name: "AddSunLight",
  emits: ["closeMenus", "addSceneItem"],
  components: { InputForm, CheckboxForm, OptionForm, ColorForm },
  props: {
    scene: { type: Scene, required: true },
  },
  data() {
    return {
      color: "#FFFFFF",
      intensity: 1,
      target: false,
      castShadow: true,
    };
  },
  methods: {
    addSunLight() {
      this.$emit("closeMenus");
      const scene = this.scene.scene;
      const color = BABYLON.Color3.FromHexString(this.color);
      const sun = new REAL.SunLight(scene, {
        diffuse: color,
        intensity: this.intensity,
        shadowEnabled: this.shadowEnabled,
      });
      sun.position.set(3, 3, 3);
      this.$emit("addSceneItem", sun, "sun_light");
    },
    setCastShadow(value) {
      this.castShadow = value;
    },
    setIntensity(value) {
      this.intensity = value;
    },
    setColor(value) {
      this.color = value;
    },
  },
};
</script>
